<template>
  <div class="Price">
    <v-parallax style="margin-top:-120px;" height="500" src="../assets/priceing.webp">
    </v-parallax>
    <v-row style="margin-left:76px; margin-right:76px; margin-top:50px;" class="d-none d-sm-flex">
      <v-col cols="3" v-for="(card, index) in cards" :key="index" :label="card" :value="card">
        <v-card class="d-flex justify-center" style="border:1px solid " :style="card.colorst" elevation="0">
          <v-row>
            <v-spacer />
            <v-col cols="10" style="margin:20px; min-height:360px">
              <div style="width:100%; text-align:center; font-family: SF Pro Text; font-size 18px; line-height: 140%; color: rgba(34, 34, 34, 0.6)">{{card.type}}</div>
              <div style="width:100%; text-align:center; font-family: SF Pro Text; font-weight: 800; font-size 24px; line-height: 200%;"><div :style="card.colorst">Free</div></div>
              <div v-if="index==3" style="text-align:center; margin-top:24px; margin-bottom:38px">
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.875 24.375C21.0171 24.375 24.375 21.0171 24.375 16.875C24.375 12.7329 21.0171 9.375 16.875 9.375C12.7329 9.375 9.375 12.7329 9.375 16.875C9.375 21.0171 12.7329 24.375 16.875 24.375Z" fill="#707070"/>
                  <path d="M16.875 28.125C11.8687 28.125 1.875 30.6375 1.875 35.625V39.375H31.875V35.625C31.875 30.6375 21.8813 28.125 16.875 28.125ZM31.425 10.05L28.275 13.2188C29.85 15.4313 29.85 18.3 28.275 20.5125L31.425 23.6813C35.2125 19.8938 35.2125 14.175 31.425 10.05ZM37.6312 3.75L34.575 6.80625C39.7688 12.4688 39.7688 20.9812 34.575 26.9437L37.6312 30C44.9437 22.7062 44.9625 11.3438 37.6312 3.75Z" fill="#707070"/>
                </svg>
              </div>
              <div v-else style="width:100%; text-align:center; font-family: SF Pro Text; font-weight: bold; color: #222222; margin-top: 24px; margin-bottom: 45px">
                <span style="line-height:140%; font-size: 18px;"> NT$ </span>
                <span style="line-height:125%; font-size: 36px; margin-left: 24px"> {{card.dol}} </span>
                <span style="line-height:140%; font-size: 18px; margin-left: 24px"> /月 </span>
              </div>
              <div style="text-align:center; margin-top:26px; ">
                <v-btn :color="card.color" style="color:white; width: 100%; height :60px; font-size: 16px" @click="send(card.type)">{{ $t('cc00') }}</v-btn>
              </div>
              <div style="height: 50px"></div>
              <!--
              <div>{{ $t('limit') }}</div>
              -->
            </v-col>
            <v-spacer />
          </v-row>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="margin-bottom:10px; margin-top:74px">
      <v-col style="text-align:center;">
        <div style="font-size:30px;font-weight:bold;">{{ $t('cc01') }}</div>
      </v-col>
    </v-row>
    <v-row style="margin-bottom:10px;">
      <v-spacer />
      <v-col cols="11">
        <v-row>
          <v-col cols="3" v-for="(option,index) in classOptions" style="text-align:center;">
            <v-card outlined style="font-size:18px;" :style="'border-color:'+ option.color">
              <p style="margin-top:40px;color:#222222">{{ option.zh }}</p>
              <p style="font-weight:800;" :style="'color:'+ option.color">{{ option.en }}</p>
              <div v-if="index==3">
                <p style="margin-top:30px;"> Contact us</p>
              </div>
              <div v-else>
                <p style="margin-top:30px;">{{ $t('cc02') }} {{ option.price }} {{ $t('cc03') }}</p>
              </div>
              <v-btn
                elevation="0"
                style="font-size:18px;margin:25px auto 10px;width:90%;color:white;height:60px;font-weight: 700;"
                :color="option.color">
                {{ $t('cc00') }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="margin:70px auto;">
      <v-spacer />
      <v-col cols="11">
        <h3 style="margin:auto;text-align:center;font-size:28px;"> {{ $t('cc01') }}</h3>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="margin-bottom:120px;">
      <v-spacer />
      <v-col cols="10">
        <v-data-table
          :headers="optionHeaders"
          :items="optionContents"
          hide-default-footer
        >
          <template v-slot:header.free="{ header }">
            <span style="font-size:18px;">{{header.text}}</span><br><span style="font-size:18px;" :style="'color:'+header.color">{{header.value.toUpperCase()}}</span>
          </template>
          <template v-slot:header.pro="{ header }">
            <span style="font-size:18px;">{{header.text}}</span><br><span style="font-size:18px;" :style="'color:'+header.color">{{header.value.toUpperCase()}}</span>
          </template>
          <template v-slot:header.business="{ header }">
            <span style="font-size:18px;">{{header.text}}</span><br><span style="font-size:18px;" :style="'color:'+header.color">{{header.value.toUpperCase()}}</span>
          </template>
          <template v-slot:header.enterprise="{ header }">
            <span style="font-size:18px;">{{header.text}}</span><br><span style="font-size:18px;" :style="'color:'+header.color">{{header.value.toUpperCase()}}</span>
          </template>
          <template v-slot:item.free="{ item }">
            <span v-if="item.free=='yes'||item.free=='no'">
              <svg v-if="item.free=='yes'" width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.29995 10.6L2.09995 6.4L0.699951 7.8L6.29995 13.4L18.3 1.4L16.9 0L6.29995 10.6Z" fill="#4DBCB8"/>
              </svg>
              <svg v-else width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5 13H5.5V11H19.5V13Z" fill="#222222" fill-opacity="0.6"/>
              </svg>
            </span>
            <span v-else-if="item.free=='button'"><v-btn
              elevation="0"
              style="font-size:18px;margin:25px auto 10px;width:90%;color:white;height:50px;font-weight: 700;"
              color="#4DBCB8">
              {{ $t('cc00') }}
            </v-btn></span>
            <span v-else>{{item.free}}</span>
          </template>
          <template v-slot:item.pro="{ item }">
            <span v-if="item.pro=='yes'||item.pro=='no'">
              <svg v-if="item.pro=='yes'" width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.29995 10.6L2.09995 6.4L0.699951 7.8L6.29995 13.4L18.3 1.4L16.9 0L6.29995 10.6Z" fill="#F8DF8B"/>
              </svg>
              <svg v-else width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5 13H5.5V11H19.5V13Z" fill="#222222" fill-opacity="0.6"/>
              </svg>
            </span>
            <span v-else-if="item.pro=='button'"><v-btn
              elevation="0"
              style="font-size:18px;margin:25px auto 10px;width:90%;color:white;height:50px;font-weight: 700;"
              color="#F8DF8B">
              {{ $t('cc00') }}
            </v-btn></span>
            <span v-else>{{item.pro}}</span>
          </template>
          <template v-slot:item.business="{ item }">
            <span v-if="item.business=='yes'||item.business=='no'">
              <svg v-if="item.business=='yes'" width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.29995 10.6L2.09995 6.4L0.699951 7.8L6.29995 13.4L18.3 1.4L16.9 0L6.29995 10.6Z" fill="#E69194"/>
              </svg>
              <svg v-else width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5 13H5.5V11H19.5V13Z" fill="#222222" fill-opacity="0.6"/>
              </svg>
            </span>
            <span v-else-if="item.business=='button'"><v-btn
              elevation="0"
              style="font-size:18px;margin:25px auto 10px;width:90%;color:white;height:50px;font-weight: 700;"
              color="#E69194">
              {{ $t('cc00') }}
            </v-btn></span>
            <span v-else>{{item.business}}</span>
          </template>
          <template v-slot:item.enterprise="{ item }">
            <span v-if="item.enterprise=='yes'||item.enterprise=='no'">
              <svg v-if="item.enterprise=='yes'" width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.29995 10.6L2.09995 6.4L0.699951 7.8L6.29995 13.4L18.3 1.4L16.9 0L6.29995 10.6Z" fill="#849BCA"/>
              </svg>
              <svg v-else width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.5 13H5.5V11H19.5V13Z" fill="#222222" fill-opacity="0.6"/>
              </svg>
            </span>
            <span v-else-if="item.enterprise=='button'"><v-btn
              elevation="0"
              style="font-size:18px;margin:25px auto 10px;width:90%;color:white;height:50px;font-weight: 700;"
              color="#849BCA">
              {{ $t('cc00') }}
            </v-btn></span>
            <span v-else>{{item.enterprise}}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>
<script>

  export default {
    name: 'Price',
    data () {
      return {
        // optionHeaders: [
        //   { text: '', value: 'function' },
        //   { text: this.$t('cct00') , value: 'free',align:'center', color: '#4DBCB8'},
        //   { text: this.$t('cct01') , value: 'pro',align:'center', color: '#F8DF8B' },
        //   { text: this.$t('cct02') , value: 'business',align:'center', color: '#E69194' },
        //   { text: this.$t('cct03') , value: 'enterprise',align:'center', color: '#849BCA' },
        // ],
        // optionContents: [
        //   {
        //     function: this.$t('cct04'),
        //     free: this.$t('cct05'),
        //     pro: this.$t('cct05'),
        //     business: this.$t('cct05'),
        //     enterprise: this.$t('cct05')
        //   },{
        //     function: this.$t('cct06'),
        //     free: this.$t('cct07'),
        //     pro: this.$t('cct08'),
        //     business: this.$t('cct05'),
        //     enterprise: this.$t('cct05')
        //   },{
        //     function: this.$t('cct09'),
        //     free: 'yes',
        //     pro: 'yes',
        //     business: 'yes',
        //     enterprise: 'yes'
        //   },{
        //     function: this.$t('cct10'),
        //     free: 'yes',
        //     pro: 'yes',
        //     business: 'yes',
        //     enterprise: 'yes'
        //   },{
        //     function: this.$t('cct11'),
        //     free: 'no',
        //     pro: 'no',
        //     business: 'no',
        //     enterprise: 'yes'
        //   },{
        //     function: this.$t('cct12'),
        //     free: 'no',
        //     pro: 'no',
        //     business: 'no',
        //     enterprise: 'yes'
        //   },{
        //     function: this.$t('cct13'),
        //     free: 'no',
        //     pro: 'no',
        //     business: 'no',
        //     enterprise: 'yes'
        //   },{
        //     function: '',
        //     free: 'button',
        //     pro: 'button',
        //     business: 'button',
        //     enterprise: 'button'
        //   },
        // ],
        // classOptions: [
        //   {
        //     zh: this.$t('cct14'),
        //     en: 'FREE',
        //     price: this.$t('price0'),
        //     color: '#4DBCB8'
        //   },{
        //     zh: this.$t('cct15'),
        //     en: 'PRO',
        //     // price: '1,000',
        //     price: this.$t('price1'),
        //     color: '#F8DF8B'
        //   },{
        //     zh: this.$t('cct16'),
        //     en: 'BUSINESS',
        //     // price: '2,000',
        //     price: this.$t('price2'),
        //     color: '#E69194'
        //   },{
        //     zh: this.$t('cct17'),
        //     en: 'ENTERPRISE',
        //     // price: 'contact',
        //     price: this.$t('price3'),
        //     color: '#849BCA'
        //   },
        // ]
      }
    },
    computed: {
      optionHeaders(){
        return[
          { text: '', value: 'function' },
          { text: this.$t('cct00') , value: 'free',align:'center', color: '#4DBCB8'},
          { text: this.$t('cct01') , value: 'pro',align:'center', color: '#F8DF8B' },
          { text: this.$t('cct02') , value: 'business',align:'center', color: '#E69194' },
          { text: this.$t('cct03') , value: 'enterprise',align:'center', color: '#849BCA' },
        ]
      } ,
      optionContents(){
        return[
          {
            function: this.$t('cct04'),
            free: this.$t('cct05'),
            pro: this.$t('cct05'),
            business: this.$t('cct05'),
            enterprise: this.$t('cct05')
          },{
            function: this.$t('cct06'),
            free: this.$t('cct07'),
            pro: this.$t('cct08'),
            business: this.$t('cct05'),
            enterprise: this.$t('cct05')
          },{
            function: this.$t('cct09'),
            free: 'yes',
            pro: 'yes',
            business: 'yes',
            enterprise: 'yes'
          },{
            function: this.$t('cct10'),
            free: 'yes',
            pro: 'yes',
            business: 'yes',
            enterprise: 'yes'
          },{
            function: this.$t('cct11'),
            free: 'no',
            pro: 'no',
            business: 'no',
            enterprise: 'yes'
          },{
            function: this.$t('cct12'),
            free: 'no',
            pro: 'no',
            business: 'no',
            enterprise: 'yes'
          },{
            function: this.$t('cct13'),
            free: 'no',
            pro: 'no',
            business: 'no',
            enterprise: 'yes'
          },{
            function: '',
            free: 'button',
            pro: 'button',
            business: 'button',
            enterprise: 'button'
          },
        ]
      } ,
      classOptions(){
        return[
          {
            zh: this.$t('cct14'),
            en: 'FREE',
            price: this.$t('price0'),
            color: '#4DBCB8'
          },{
            zh: this.$t('cct15'),
            en: 'PRO',
            // price: '1,000',
            price: this.$t('price1'),
            color: '#F8DF8B'
          },{
            zh: this.$t('cct16'),
            en: 'BUSINESS',
            // price: '2,000',
            price: this.$t('price2'),
            color: '#E69194'
          },{
            zh: this.$t('cct17'),
            en: 'ENTERPRISE',
            // price: 'contact',
            price: this.$t('price3'),
            color: '#849BCA'
          },
        ]
      } ,
    },
    components: {
    },
    methods: {
      send(type){
        console.log('send btn', type)
      }
    }
  }
</script>


<style scoped>
.t-title {
/* Caption */

font-family: SF Pro Text;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 140%;
/* or 25px */

text-align: center;
}

</style>
