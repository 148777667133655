<template>
  <div class="Contact">
    <v-parallax style="margin-top:-120px;" height="500" src="../assets/contact.webp">
      <v-row style="margin-top:200px;" class="d-none d-sm-flex">
        <v-spacer />
        <v-col cols="8">
          <div style="opacity:.9;font-size:48px;">{{$t('contact')}}</div>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row style="margin-top:200px;text-align:center;" class="d-flex d-sm-none">
        <v-col cols="12">
          <div style="opacity:.9;font-size:48px;">{{$t('contact')}}</div>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row style="margin:-320px auto 60px auto;" class="d-none d-sm-flex">
      <v-spacer />
      <v-col :cols="6" :offset-sm="5">
        <v-card  style="margin-right:50px;">
          <v-form ref="form" style="margin:auto" >
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.companyname.value"
                  :counter="10"
                  :label="form.companyname.label"
                  :rules="[v => !!v || $t('companyname require')]"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.companytel.value"
                  :counter="10"
                  :label="form.companytel.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <div style="opacity:.7;">{{ form.formkind.label }}</div>
                <v-radio-group
                  v-model="form.formkind.value"
                  :rules="[v => !!v || $t('formkind require')]"
                  required
                >
                  <v-radio
                    v-for="radio in radios"
                    :key="radio"
                    :label="radio"
                    :value="radio"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-textarea
                  name="input-7-1"
                  color="#4DBCB8"
                  background-color="white"
                  outlined
                  :label="form.text.label"
                  auto-grow
                  v-model="form.text.value"
                ></v-textarea>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contactname.value"
                  :counter="10"
                  :label="form.contactname.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contacttel.value"
                  :counter="10"
                  :label="form.contacttel.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contactemail.value"
                  :counter="30"
                  :label="form.contactemail.label"
                  :rules="[v => !!v || $t('contactemail require')]"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contactother.value"
                  :counter="10"
                  :label="form.contactother.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10" style="text-align:center;">
                <v-btn large :loading="sendload" color="#4DBCB8" style="color:white;width:160px;" @click="send">{{ $t('send') }}</v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="margin:40px auto 60px auto;" class="d-flex d-sm-none">
      <v-spacer />
      <v-col :cols="12">
          <v-form style="margin:auto" >
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.companyname.value"
                  :counter="10"
                  :label="form.companyname.label"
                  :rules="[v => !!v || $t('companyname require')]"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.companytel.value"
                  :counter="10"
                  :label="form.companytel.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <div style="opacity:.7;">{{ form.formkind.label }}</div>
                <v-radio-group
                  v-model="form.formkind.value"
                  :rules="[v => !!v || $t('formkind require')]"
                  required
                >
                  <v-radio
                    v-for="radio in radios"
                    :key="radio"
                    :label="radio"
                    :value="radio"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-textarea
                  name="input-7-1"
                  color="#4DBCB8"
                  background-color="white"
                  outlined
                  :label="form.text.label"
                  auto-grow
                  v-model="form.text.value"
                ></v-textarea>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contactname.value"
                  :counter="10"
                  :label="form.contactname.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contacttel.value"
                  :counter="10"
                  :label="form.contacttel.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contactemail.value"
                  :counter="30"
                  :label="form.contactemail.label"
                  :rules="[v => !!v || $t('contactemail require')]"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10">
                <v-text-field
                  style="width:100%"
                  v-model="form.contactother.value"
                  :counter="10"
                  :label="form.contactother.label"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col cols="10" style="text-align:center;">
                <v-btn large color="#4DBCB8" style="color:white;width:160px;" @click="send">{{ $t('send') }}</v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
    <v-snackbar
      v-model="snackbar"
      color="#4DBCB8"
    >
      {{ $t('thanks') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import api from '../api/email';

  export default {
    name: 'Contact',
    data () {
      return {
        snackbar: false,
        sendload: false,
        radios: [
          this.$t('radio0'),
          this.$t('radio1'),
          this.$t('radio2'),
          this.$t('radio3'),
          this.$t('radio4'),
          this.$t('radio5')
        ],
        form: {
          companyname: {
            label: this.$t('companyname'),
            value: null
          },
          companytel: {
            label: this.$t('companytel'),
            value: null
          },
          formkind: {
            label: this.$t('kind'),
            value: null
          },
          text: {
            label: this.$t('text'),
            value: null
          },
          contactname: {
            label: this.$t('contactname'),
            value: null
          },
          contacttel: {
            label: this.$t('contacttel'),
            value: null
          },
          contactemail: {
            label: this.$t('contactemail'),
            value: null
          },
          contactother: {
            label: this.$t('contactother'),
            value: null
          }
        }
      }
    },
    computed: {

    },
    components: {
    },
    methods: {
      send() {
        console.log(this.$refs.form.validate())
        if(this.$refs.form.validate()){
          this.$set(this, 'sendload', true)
          console.log('fire send')
          api
          .email({ data: this.form })
          .then(({ data }) => {
            if (data.success) {
              this.$set(this, 'sendload', false)
              this.$refs.form.reset()
              this.$set(this, 'snackbar', true)
              console.log('get state', data.data);
            } else {
              console.log('cannot get state');
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }
      }
    }
  }
</script>
