<template>
  <v-app>
    <span class="d-none d-sm-flex">
      <v-app-bar
        app
        color="rgba(255,255,255,.0)"
        hide-on-scroll
        dark
        height="80"
      >
        <div class="d-flex align-center">

          <v-img
            alt="Aortac Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            :src="require('./assets/logo.svg')"
            width="100"
            style="margin-left:200px;cursor:pointer;"
            @click="go('/')"
          />
        </div>
        <div style="margin-left:60px;cursor:pointer;" @click="go('/features')">
          {{$t('features')}}
        </div>
        <div style="margin-left:40px;cursor:pointer;" @click="go('/price')">
          {{$t('pricing')}}
        </div>
        <div style="margin-left:40px;cursor:pointer;" @click="go('/contact')">
          {{$t('contact')}}
        </div>
        <v-spacer></v-spacer>
        <div style="width:100px;margin-top:26px;margin-right:30px;">
          <v-select
            :items="lans"
            v-model="lan"
            outlined
            dense
            @change="translate"
          ></v-select>
        </div>
        <v-btn
          text
          outlined
          style="height:40px;margin-right:30px;border-color:white;"
          @click="goTo('https://app.aortac.com')"
        >
          {{ $t('login') }}
        </v-btn>
        <v-btn
          text
          style="background-color:#4DBCB8;height:40px;margin-right:60px;"
          @click="goTo('https://app.aortac.com')"
        >
          {{ $t('signup') }}
        </v-btn>
      </v-app-bar>
      <v-app-bar
        app
        color="rgba(255,255,255)"
        inverted-scroll
        height="80"
        style="z-index:999"
        scroll-threshold="300"
      >
        <div class="d-flex align-center">

          <v-img
            alt="Aortac Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            :src="require('./assets/logo.svg')"
            width="100"
            style="margin-left:200px;cursor:pointer;"
            @click="go('/')"
          />
        </div>
        <div style="margin-left:60px;cursor:pointer;" @click="go('/features')">
          {{$t('features')}}
        </div>
        <div style="margin-left:40px;cursor:pointer;" @click="go('/price')">
          {{$t('pricing')}}
        </div>
        <div style="margin-left:40px;cursor:pointer;" @click="go('/contact')">
          {{$t('contact')}}
        </div>
        <v-spacer></v-spacer>
        <div style="width:100px;margin-top:26px;margin-right:30px;">
          <v-select
            :items="lans"
            v-model="lan"
            outlined
            dense
            @change="translate"
          ></v-select>
        </div>
        <v-btn
          text
          outlined
          style="height:40px;margin-right:30px;border-color:white;"
          @click="goTo('https://app.aortac.com')"
        >
          {{ $t('login') }}
        </v-btn>
        <v-btn
          text
          style="background-color:#4DBCB8;height:40px;margin-right:60px;"
          @click="goTo('https://app.aortac.com')"
        >
          {{ $t('signup') }}
        </v-btn>
      </v-app-bar>
    </span>
    <span class="d-flex d-sm-none">
      <v-app-bar
        app
        color="rgba(255,255,255,.0)"
        elevate-on-scroll
        dark
        height="80"
      >
        <v-row>
          <v-col cols="2">
            <v-img
              alt="Aortac Name"
              contain
              min-width="100"
              :src="require('./assets/logo.svg')"
              width="100"
              style="cursor:pointer;"
              @click="go('/')"
            />
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="drawer=true">
              <path d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z" fill="white" fill-opacity="0.9"/>
            </svg>
          </v-col>
        </v-row>
      </v-app-bar>
    </span>
    <v-navigation-drawer
      v-model="drawer"
      style="background-color:rgba(0,0,0,.65)"
      absolute
      temporary
      width="100%"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="cursor:pointer;float:right;color:white;margin:24px 16px"
        @click="drawer=false">
        <g clip-path="url(#clip0_402_376)">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" fill-opacity="0.9"/>
        </g>
        <defs>
        <clipPath id="clip0_402_376">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      <div
        style="margin:100px auto 0px auto;cursor:pointer;color:white;text-align:center;font-size:22px;"
        @click="go('/features')"
      >
        {{$t('features')}}
      </div>
      <div
        style="margin:20px auto 0px auto;cursor:pointer;color:white;text-align:center;font-size:22px;"
        @click="go('/price')"
      >
        {{$t('pricing')}}
      </div>
      <div
        style="margin:20px auto 0px auto;cursor:pointer;color:white;text-align:center;font-size:22px;"
        @click="go('/contact')"
      >
        {{$t('contact')}}
      </div>
      <v-spacer></v-spacer>
      <div style="width:100px;margin:80px auto 20px auto;color:white;">
        <v-select
          :items="lans"
          v-model="lan"
          outlined
          dark
          dense
          @change="translate"
        ></v-select>
      </div>
      <div style="text-align:center;">
        <v-btn
          text
          style="background-color:#4DBCB8;height:40px;margin:50px auto 0px auto;color:white;width:80%;"
          @click="goTo('https://app.aortac.com')"
        >
          {{ $t('signup') }}
        </v-btn>
      </div>
      <div style="text-align:center;">
        <v-btn
          text
          outlined
          style="height:40px;margin:20px auto;border-color:white;color:white;width:80%;"
          @click="goTo('https://app.aortac.com')"
        >
          {{ $t('login') }}
        </v-btn>
      </div>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
    <v-row style="background-color:#4DBCB8;height:200px;text-align:center;cursor:pointer;">
      <v-spacer />
      <v-col cols="10">
        <div style="margin-top:68px;color:white;font-size:30px;" @click="goTo('https://app.aortac.com')">
          <span>
          {{ $t('cc1') }}
            <svg style="margin-bottom:-20px;" width="42" height="30" viewBox="0 0 42 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.5" y1="-0.5" x2="7.98528" y2="-0.5" transform="matrix(-0.814541 0.580105 -0.580125 -0.814527 30.4165 6.36377)" stroke="white" stroke-linecap="round"/>
              <line x1="0.5" y1="-0.5" x2="7.98528" y2="-0.5" transform="matrix(-0.380724 0.924689 -0.924692 -0.380717 21.6741 0)" stroke="white" stroke-linecap="round"/>
              <line x1="0.5" y1="-0.5" x2="7.98528" y2="-0.5" transform="matrix(0.396927 0.91785 -0.917873 0.396875 9.96075 0.825195)" stroke="white" stroke-linecap="round"/>
              <line x1="0.5" y1="-0.5" x2="7.98528" y2="-0.5" transform="matrix(0.921019 0.389518 -0.389565 0.920999 1.72748 9.29395)" stroke="white" stroke-linecap="round"/>
              <line x1="0.5" y1="-0.5" x2="7.98528" y2="-0.5" transform="matrix(0.990759 -0.135633 0.135667 0.990754 1 19.4004)" stroke="white" stroke-linecap="round"/>
              <path d="M16.1317 42.9074L16.1317 42.9073L16.732 16.0452C16.732 16.0452 16.732 16.0452 16.732 16.0451C16.7383 15.7672 17.0719 15.6289 17.273 15.8208C17.273 15.8208 17.273 15.8208 17.273 15.8208L36.7083 34.3733L36.7084 34.3733C36.9043 34.5603 36.7936 34.8916 36.524 34.9228L28.9416 35.7995L28.2833 35.8756L28.5372 36.4877L32.9794 47.1987C33.0471 47.362 32.9697 47.5493 32.8064 47.617L29.0188 49.1878C28.8555 49.2556 28.6682 49.1781 28.6005 49.0148L24.0686 38.0874L23.8147 37.4753L23.2958 37.8874L16.6508 43.1651C16.4383 43.3338 16.1256 43.1781 16.1317 42.9074Z" fill="white" stroke="white"/>
            </svg>
          </span>
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="background-color:rgb(68, 68, 68);height:200px;">
      <v-spacer />
      <v-col cols="8">
        <v-row >
          <v-spacer />
          <v-col cols="4">
            <v-img
              alt="Aortac Name"
              min-width="100"
              :src="require('./assets/whitelogo.svg')"
              width="100"
              style="margin:50px 0px 40px 0px;"
            />
            <div style="color:white;">
              © 2022 Allkym Co Ltd. All rights reserved.
            </div>
          </v-col>
          <v-col cols="2">
            <v-divider
              class="d-none d-sm-flex"
              color="white"
              style="height:10%;opacity:0.3;margin:16px auto 0px auto;"
              vertical
            ></v-divider>
          </v-col>
          <v-col cols="4" style="margin-top:70px;">
            <svg width="30" height="30" class="footerlogo" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM20.0121 12.6725L19.5052 15.9828H16.831V23.9897H13.2517V15.9828H10.3448V12.6725H13.2517V10.1484C13.2517 7.27766 14.9638 5.68973 17.581 5.68973C18.8328 5.68973 20.1466 5.91215 20.1466 5.91215V8.73111H18.7035C17.281 8.73111 16.8362 9.6156 16.8362 10.5208V12.6725H20.0121Z" fill="white"/>
            </svg>
            <svg width="30" height="30" class="footerlogo" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM8.54186 12.7643H11.3209V21.7354H8.54186V12.7643ZM9.91728 11.5927C10.8229 11.5927 11.5587 10.8512 11.5587 9.93426C11.5587 9.01733 10.8229 8.27587 9.91728 8.27587C9.01167 8.27587 8.27587 9.01733 8.27587 9.93426C8.27587 10.8512 9.00601 11.5927 9.91728 11.5927ZM17.4508 15.017C16.3414 15.017 15.7584 15.7698 15.7584 17.032V17.0341C15.7584 18.4164 15.7584 21.7411 15.7528 21.7355H13.0869V12.7643H15.7528V13.9755C15.7528 13.9755 16.5565 12.4926 18.4583 12.4926C20.3601 12.4926 21.7241 13.6529 21.7241 16.0584V21.7411H18.962V17.032C18.962 15.7358 18.4696 15.017 17.4508 15.017Z" fill="white"/>
            </svg>
            <svg width="30" height="30" class="footerlogo" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15ZM7.72552 9.64915L14.9756 15.7198L22.2256 9.64915H7.72552ZM15.3183 16.848C15.3194 16.8468 15.3205 16.8457 15.3227 16.8457L23 10.4191V20.4952H6.96001V10.4191L14.6329 16.8457C14.6351 16.8457 14.6362 16.8468 14.6373 16.848C14.6384 16.8491 14.6395 16.8502 14.6418 16.8502C14.6863 16.8858 14.7308 16.9169 14.7842 16.9347C14.7947 16.9347 14.8025 16.9375 14.8119 16.9409C14.8144 16.9418 14.817 16.9427 14.8198 16.9436C14.8732 16.957 14.9266 16.9703 14.98 16.9703C15.0334 16.9703 15.0868 16.9614 15.1402 16.9436C15.1467 16.9436 15.1533 16.9413 15.1615 16.9382C15.1645 16.9371 15.1678 16.9359 15.1714 16.9347C15.2203 16.9125 15.2693 16.8858 15.3138 16.8502C15.316 16.8502 15.3171 16.8491 15.3183 16.848Z" fill="white"/>
            </svg>
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-app>
</template>
<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    lan: 'EN',
    lans: [
      'EN',
      '中文'
    ]
  }),
  mounted() {
    console.log(navigator.language)
    if(navigator.language==='zh-TW'){
      this.$set(this.$root.$i18n,'locale','zh')
      this.$set(this,'lan','中文')
    }else{
      this.$set(this.$root.$i18n,'locale','en')
      this.$set(this,'lan','en')
    }
  },
  methods: {
    go(url) {
      this.$router.push(url)
      this.drawer = false
    },
    goTo(path) {
      window.open(path)
    },
    translate(lan) {
      console.log(lan, 'KKKKK')
      if(lan=='中文'){
        this.$set(this.$root.$i18n,'locale','zh')
      }else{
        this.$set(this.$root.$i18n,'locale','en')
      }
      this.drawer = false
      //this.$set(this.$root.$i18n,'locale',lan)
      // $i18n.locale = 'zh'
    }
  }
};
</script>
<style scoped>
.footerlogo{
  margin-right:30px;
  cursor:pointer;
  opacity:.7;
}
</style>
