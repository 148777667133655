<template>
  <div class="features">
    <v-parallax style="margin-top:-120px;" height="500" src="../assets/features.webp">
      <v-row justify="left" align="left" style="margin-top:200px;font-size:35px;">
        <v-col cols="12" style="text-align:center;">
          <div style="background-color:rgba(0,0,0,.8);width:40%;margin:auto;padding: 40px 0px;border-radius:0px 50px 0px 50px;">
            <div>{{ $t('functions') }}</div>
            <div style="font-size:24px;margin-top:30px;">{{ $t('easyway') }}</div>
          </div>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row>
    </v-row>
    <v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="5">
        <v-img src="../assets/01.jpg" style="height:400px;width:800px;margin:60px -100px 0px 0px; background-position: unset ;">
          <div style="padding:40px;">
            <div style="font-size:36px;">{{ funcdashboard.title }}</div>
            <div style="font-size:24px;margin-top:20px;">{{ $t('qq0') }}</div>
            <div style="font-size:24px;margin-top:14px;">{{ $t('qq1') }}</div>
            <div style="font-size:24px;margin-top:14px;">{{ $t('qq2') }}</div>
            <!--
            <div v-for="de in funcdashboard.detail" style="font-size:24px;margin-top:10px;">
              <div>
                <v-icon
                  style="font-size:2px;"
                >
                  mdi-checkbox-blank-circle
                </v-icon>
                {{ de.title }}
              </div>
              <div>
                {{ de.content }}
              </div>
            </div>
            -->
          </div>
        </v-img>
      </v-col>
      <v-col cols="6" style="">
        <v-img src="../assets/funcdashboard.png" style="margin:120px 0px 60px 0px" />
      </v-col>
    </v-row>
    <v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="4" style="">
        <v-img src="../assets/Flow.png" style="width:480px;margin:120px 0px 60px 0px;z-index:20;" />
      </v-col>
      <v-col cols="6">
        <v-img src="../assets/02.jpg" style="height:320px;width:680px;margin:60px 0px 0px -120px; background-position: unset ;">
          <div style="padding:60px 0px 100px 0px;width:400px;margin:auto;">
            <div style="font-size:36px;">{{ $t('ff01') }}</div>
            <div style="font-size:24px;margin-top:20px;">{{ $t('ff02') }}</div>
          </div>
        </v-img>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="7">
        <v-img src="../assets/03.jpg" style="height:300px;width:630px;margin:60px -100px 0px auto;">
          <div style="padding:60px;margin-top:60px;height:680px;width:580px;">
            <div style="font-size:36px;">{{ $t('ff22') }}</div>
            <div style="font-size:24px;margin-top:20px;">{{ $t('ff03') }}</div>
            <div style="font-size:24px;">{{ $t('ff04') }}</div>
          </div>
        </v-img>
      </v-col>
      <v-col cols="3" style="">
        <v-img src="../assets/RealFlow.png" style="width:300px;margin:-320px 0px 60px 0px" />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="5">
        <v-img src="../assets/04.jpg" style="height:300px;width:630px;margin:60px 0px 0px 0px;">
          <div style="padding:60px;margin-top:60px;height:680px;width:580px;">
            <div style="font-size:36px;">{{ $t('ff05') }}</div>
            <div style="font-size:24px;margin-top:20px;">{{ $t('ff06') }}</div>
            <div style="font-size:24px;">{{ $t('ff07') }}</div>
          </div>
        </v-img>
      </v-col>
      <v-col cols="5" style="">
        <v-img src="../assets/Member.png" style="margin:120px 0px 60px -80px" />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="5" style="">
        <v-img src="../assets/Statistic.png" style="margin:120px 0px 0px -80px" />
        <v-img src="../assets/PieChart.png" style="width:500px;margin:0px 0px 0px 0px" />
        <v-img src="../assets/Search.png" style="width:100px;margin:-400px 0px 60px 420px" />
      </v-col>
      <v-col cols="5">
        <v-img src="../assets/05.jpg" style="height:600px;width:100%;margin:60px 0px 0px 0px;">
          <div style="padding:60px;margin-top:60px;">
            <div style="font-size:36px;">{{ $t('ff08') }}</div>
            <div style="font-size:24px;margin-top:20px;">{{ $t('ff09') }}</div>
            <div style="font-size:24px;">{{ $t('ff10') }}</div>
            <div style="font-size:24px;">{{ $t('ff11') }}</div>
            <div style="font-size:24px;">{{ $t('ff12') }}</div>
            <div style="font-size:24px;">{{ $t('ff13') }}</div>
          </div>
        </v-img>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="d-none d-sm-flex" style="background-color:#D0ECEC;margin-top:100px;">
      <v-spacer />
      <v-col cols="8">
        <v-data-table
          :headers="featureHeaders"
          :items="featureContents"
          style="background-color:transparent;margin:20px auto"
          hide-default-footer
          disable-sort
        >
          <!-- <template v-slot:header.function="{ header }">
            <span style="font-size:20px;color:black">{{header.text}}</span>
          </template> -->
          <template v-slot:header.cloud="{ header }">
            <span style="font-size:20px;color:black">{{header.text}}</span>
          </template>
          <template v-slot:header.aortac="{ header }">
            <v-img
              alt="Aortac"
              contain
              min-width="80"
              :src="require('.././assets/logo.svg')"
              width="80"
              style="margin: 0px auto 4px auto;"
            />
          </template>
          <template v-slot:header.tradition="{ header }">
            <span style="font-size:20px;color:black;">{{header.text}}</span>
          </template>
          <template v-slot:item.function="{ item }">
            <div style="margin:20px auto"><span style="font-size:20px;color:black;">{{item.function}}</span></div>
          </template>
          <template v-slot:item.cloud="{ item }">
            <span style="font-size:20px;color:black">{{item.cloud}}</span>
          </template>
          <template v-slot:item.aortac="{ item }">
            <span style="font-size:20px;color:black">{{item.aortac}}</span>
          </template>
          <template v-slot:item.tradition="{ item }">
            <span style="font-size:20px;color:black">{{item.tradition}}</span>
          </template>
        </v-data-table>
      </v-col>
      <v-spacer />
    </v-row>
    <!-- <v-row class="d-none d-sm-flex" style="background-color:#D0ECEC;margin-top:100px;">
      <v-spacer />
      <v-col cols="8">
        <v-img src="../assets/compare.png" style="" />
      </v-col>
      <v-spacer />
    </v-row> -->
    <v-row class="d-none d-sm-flex" style="margin-top:100px;">
      <v-col cols="6">
        <v-img src="../assets/Process.png" style="" />
      </v-col>
      <v-col cols="4">
        <div style="padding:60px;margin-top:60px;height:680px;width:580px;">
          <div style="font-size:36px;">{{ $t('ff14') }}</div>
          <div style="font-size:24px;">{{ $t('ff15') }}</div>
          <div style="font-size:24px;">{{ $t('ff16') }}</div>
          <div style="font-size:24px;">{{ $t('ff17') }}</div>
          <div style="font-size:24px;">{{ $t('ff18') }}</div>
          <div style="font-size:24px;">{{ $t('ff19') }}</div>
          <div style="font-size:24px;">{{ $t('ff20') }}</div>
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <!-- Old Start-->
    <!--<v-row style="background-color:#F0F0F0;" class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="2" v-for="func in funcfeatures" style="margin-top:10px;">
        <v-hover
          v-slot="{ hover }"
        >
        <v-card
          :elevation="hover ? 12 : 2"
          :class="{ 'on-hover': hover }"
          outlined
          style="border-color:rgba(77,188,184,.5);padding:10px 4px;height:450px;margin:70px auto 50px auto;background-color:transparent;">
          <div style="text-align:center;"><v-img style="width:100px;margin:auto;" :src="func.icon" /></div>
          <div style="font-size:30px;margin-top:20px;padding:2px;">{{ func.title }}</div>
          <div style="margin-top:20px;padding:2px;">{{ func.content }}</div>
        </v-card>
        </v-hover>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="background-color:#F0F0F0;margin-top:0px;" v-for="func in funcfeatures" class="d-flex d-sm-none">
      <v-spacer />
      <v-col cols="11" style="margin-top:0px;text-align:center">
        <v-card
          outlined
          style="border-color:rgba(77,188,184,.5);padding:10px 4px;height:240px;margin:0px auto 0px auto;background-color:transparent;">
          <v-row>
            <v-col cols="4">
              <v-img style="width:100px;" :src="func.icon" />
            </v-col>
            <v-col cols="8">
              <div style="font-size:30px;margin-top:20px;padding:2px;text-align:left;">{{ func.title }}</div>
            </v-col>
          </v-row>
          <div style="margin:14px 10px;padding:2px;text-align:left;">{{ func.content }}</div>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>-->
    <!--<v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="4">
        <div style="font-size:36px;margin-top:80px;">{{ funcdashboard.title }}</div>
        <div v-for="de in funcdashboard.detail" style="font-size:24px;margin-top:10px;">
          <div>
            <v-icon
              style="font-size:2px;"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ de.title }}
          </div>
          <div>
            {{ de.content }}
          </div>
        </div>
      </v-col>
      <v-col cols="7" style="">
        <v-img src="../assets/funcdashboard.jpg" style="width:1000px;margin:40px -400px 60px 0px" />
      </v-col>
    </v-row>
    <v-row class="d-flex d-sm-none">
      <v-spacer />
      <v-col cols="5">
        <div style="font-size:36px;margin:10px 0px 0px 20px;">{{ funcdashboard.title }}</div>
        <div v-for="de in funcdashboard.detail" style="font-size:14px;margin:10px 0px 0px 20px;">
          <div>
            <v-icon
              style="font-size:2px;"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ de.title }}
          </div>
          <div>
            {{ de.content }}
          </div>
        </div>
      </v-col>
      <v-col cols="7" style="">
        <v-img src="../assets/funcdashboard.jpg" style="width:1000px;margin:140px 0px 10px 0px" />
      </v-col>
    </v-row>
    <v-row style="background-color:#F0F0F0;" class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="5">
        <v-img src="../assets/chart.svg" />
      </v-col>
      <v-spacer />
      <v-col cols="4">
        <div style="font-size:36px;margin-top:80px;">{{ funcstatistic.title }}</div>
        <div v-for="de in funcstatistic.detail" style="font-size:24px;margin-top:10px;">
          <div>
            <v-icon
              style="font-size:2px;"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ de.content }}
          </div>
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="background-color:#F0F0F0;" class="d-flex d-sm-none">
      <v-col cols="12">
        <div style="font-size:36px;margin:20px 0px 10px 20px;">{{ funcstatistic.title }}</div>
        <div v-for="de in funcstatistic.detail" style="font-size:24px;margin:0px 0px 0px 20px;">
          <div>
            <v-icon
              style="font-size:2px;"
            >
              mdi-checkbox-blank-circle
            </v-icon>
            {{ de.content }}
          </div>
        </div>
        <div>
          <v-img src="../assets/chart.svg" style="width:90%;margin:20px auto 10px auto"/>
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="6" style="margin-bottom:40px;">
        <div style="font-size:36px;margin:30px auto">
          {{ funcflow.title }}
        </div>
        <div style="font-size:24px;margin:10px auto">
          {{ funcflow.detail[0].content }}
        </div>
        <v-img src="../assets/flow.jpg" />
      </v-col>
      <v-col cols="4">
        <div style="font-size:24px;margin:156px 0px 0px 40px">
          {{ funcflow.detail[1].content }}
        </div>
        <div style="font-size:24px;margin:10px 0px 40px 40px">
          {{ funcflow.detail[2].content }}
        </div>
        <v-img style="width:140px;margin-left:40px;" src="../assets/history.jpg" />
      </v-col>
      <v-spacer />
    </v-row>
    <v-row class="d-flex d-sm-none">
      <v-col cols="12" style="margin-bottom:20px;">
        <div style="font-size:36px;margin:20px 0px 0px 20px">
          {{ funcflow.title }}
        </div>
        <div style="font-size:24px;margin:10px 0px 10px 20px">
          {{ funcflow.detail[0].content }}
        </div>
        <div>
          <v-img src="../assets/flow.jpg" style="width:90%;margin:auto;"/>
        </div>
      </v-col>
      <v-col cols="12" style="text-align:center">
        <div style="font-size:24px;margin:0px auto">
          {{ funcflow.detail[1].content }}
        </div>
        <div style="font-size:24px;margin:0px auto 20px auto">
          {{ funcflow.detail[2].content }}
        </div>
        <div>
          <v-img style="width:50%;margin:10px auto;" src="../assets/history.jpg" />
        </div>
      </v-col>
    </v-row>
    <v-row style="background-color:#A5DEE4;" class="d-none d-sm-flex">
      <v-spacer />
      <v-col cols="6">
        <v-img style="margin:140px auto;" src="../assets/member.jpg" />
      </v-col>
      <v-col cols="4" style="margin-top:100px;">
        <div style="font-size:36px;margin:40px auto">
          {{ funcmember.title }}
        </div>
        <div v-for="de in funcmember.detail" style="font-size:24px;margin:10px 0px 0px 0px">
          <v-icon
            style="font-size:2px;"
          >
            mdi-checkbox-blank-circle
          </v-icon>
          {{ de.content }}
        </div>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row style="background-color:#A5DEE4;" class="d-flex d-sm-none">
      <v-spacer />
      <v-col cols="12" style="margin-top:10px;">
        <div style="font-size:36px;margin:0px 0px 0px 20px">
          {{ funcmember.title }}
        </div>
        <div v-for="de in funcmember.detail" style="font-size:24px;margin:0px 0px 0px 20px">
          <v-icon
            style="font-size:2px;"
          >
            mdi-checkbox-blank-circle
          </v-icon>
          {{ de.content }}
        </div>
      </v-col>
      <v-col cols="12">
        <v-img style="margin:0px auto 20px auto;width:90%;" src="../assets/member.jpg" />
      </v-col>
      <v-spacer />
    </v-row>-->
    <!-- Old End-->
  </div>
</template>
<script>

  export default {
    name: 'features',
    data () {
      return {
      }
    },
    computed: {
      funcmember() {
        return {
          title: this.$t('Member'),
          detail: [
            {
              content: this.$t('member0')
            },{
              content: this.$t('member1')
            }
          ],
        }
      },
      featureHeaders(){
        return [
         {text: '', value: 'function', align:'center'},
         {text: this.$t('fft01'), value: 'cloud',align:'center', color: '#4DBCB8'},
         {text: this.$t('fft02'), value: 'aortac',align:'center', color: '#F8DF8B'} ,
         {text: this.$t('fft03'), value: 'tradition',align:'center', color: '#E69194'} ,
        ]
      },
      featureContents(){
        return [
          {
            function: this.$t('fft04'),
            cloud: this.$t('fft05'),
            aortac: this.$t('fft06'),
            tradition: this.$t('fft07'),
          },{
            function: this.$t('fft08'),
            cloud: '-',
            aortac: 'yes',
            tradition: this.$t('fft09'),
          },,{
            function: this.$t('fft10'),
            cloud: '-',
            aortac: 'yes',
            tradition: this.$t('fft11'),
          },{
            function: this.$t('fft12'),
            cloud: this.$t('fft13'),
            aortac: this.$t('fft14'),
            tradition: this.$t('fft15'),
          },{
            function: this.$t('fft16'),
            cloud: this.$t('fft17'),
            aortac: this.$t('fft18'),
            tradition: this.$t('fft19'),
          },{
            function: this.$t('fft20'),
            cloud: this.$t('fft21'),
            aortac: this.$t('fft22'),
            tradition: this.$t('fft23'),
          }

        ]
      },
      funcflow() {
        return {
          title: this.$t('Flow'),
          detail: [
            {
              content: this.$t('flow0')
            },{
              content: this.$t('flow1')
            },{
              content: this.$t('flow2')
            }
          ]
        }
      },
      funcstatistic() {
        return {
          title: this.$t('Statistic'),
          detail: [
            {
              content: this.$t('statistic0')
            },{
              content: this.$t('statistic1')
            },{
              content: this.$t('statistic2')
            },{
              content: this.$t('statistic3')
            },
          ]
        }
      },
      funcdashboard() {
        return {
          title: this.$t('Dashboard'),
          detail: [
            {
              title: 'My work',
              content: this.$t('detail0')
            },{
              title: 'All',
              content: this.$t('detail1')
            },{
              title: 'Outstanding Payment',
              content: this.$t('detail2')
            },{
              title: 'Archive',
              content: this.$t('detail3')
            },
          ]
        }
      },
      funcfeatures() {
        return [
          {
            title: this.$t('func0'),
            content: this.$t('funccontent0'),
            icon: require('../assets/funcicon0.svg')
          },{
            title: this.$t('func1'),
            content: this.$t('funccontent1'),
            icon: require('../assets/funcicon1.svg')
          },{
            title: this.$t('func2'),
            content: this.$t('funccontent2'),
            icon: require('../assets/funcicon2.svg')
          },{
            title: this.$t('func3'),
            content: this.$t('funccontent3'),
            icon: require('../assets/funcicon3.svg')
          },{
            title: this.$t('func4'),
            content: this.$t('funccontent4'),
            icon: require('../assets/funcicon4.svg')
          },
        ]
      }
    },
    components: {
    },
  }
</script>
<style scoped>
#para1 {
}
</style>
<style>
.v-image__image{
  background-position: unset !important;
}

</style>